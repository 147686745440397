import React, {useEffect} from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

const NoInternetView = (props) => {

    useEffect(() => {
        window.addEventListener('online', resumeOperation);

        return () => {
            window.removeEventListener('online', resumeOperation);
        }
    }, []);

    const resumeOperation = () => {
        if (props.history) {
            // go back.
            props.history.go(-1);
        }
    }
    return (
        <Column height="100%" heights="fill" spacingInset={"medium"}>
            <Column alignmentHorizontal="center" spacingInset={"medium"} spacing={"medium"}>
                <Text type="h500" alignment="center"><FormattedMessage id={props.title}/></Text>
                <img src={props.noInternetImage} width="50%" alt={"no internet"}/>
            </Column>
            <Column width="100%" alignmentVertical="bottom">
                <Button size="large" type="primary" onClick={() => {window.location.href = "app://open.wifi.settings"}}>
                    <FormattedMessage id={props.openSettingsButtonText}/>
                </Button>
            </Column>
        </Column>
    );
};

NoInternetView.propTypes = {
    title: PropTypes.string.isRequired,
    openSettingsButtonText: PropTypes.string.isRequired,
    noInternetImage: PropTypes.string.isRequired
};

export default NoInternetView;
import React, {Component, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import PropTypes from "prop-types";
import HeaderView from "../HeaderView";
import {Logger} from "@amzn/dolphin-web-framework";
import {getContainerId} from "../Utils/ScannerUtils";
import { NativeMeshInteractor, TokenScanHandler } from "@amzn/dolphin-web-framework";
import { withRouter } from 'react-router-dom';
import UIUtils from "../Utils/UIUtils";
import {FormattedMessage, injectIntl} from "react-intl";
import {STRINGS} from "../../il8n/strings";
import Alert from "@amzn/meridian/alert";

const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
class ScanTokenView extends Component {

    keyDown = (e) => {
        if (e.key === "Enter" && this.props.onSubmit) {
            Logger.log.info("Scan received via " + e.key + " " + this?.scanRef?.value);
            if (UIUtils.isScanEventAllowed()) {
                if (!this.scanRef.value) {
                    Logger.log.info("Empty Scan received in ScanView");
                    this.scanRef.blur();
                    return;
                }
                const data = getContainerId(this.scanRef.value);
                this.propagateScan(data);
                this.scanRef.value = "";
            } else {
                Logger.log.info("Scan Throttled");
                this.scanRef.value = "";
            }
        }
    }

    propagateScan = (data) => {
        this.props.onSubmit(data.trim());
    };

    requestFocus = (time) => {
        if (this.props.focusLock) {
            // Set read only true then false to avoid keyboard popping up
            setTimeout(() => {
                if (this.scanRef && (document.activeElement !== this.scanRef || this.scanRef.readOnly)) {
                    this.scanRef.readOnly = true;
                    this.scanRef.focus();
                    this.scanRef.readOnly = false;
                }
            }, time);
        }
    }

    eventListener = (event) => {
        try {
            const eventData = event.data;
            if (eventData === "disableScan") {
                this.scanRef.inputMode = "none";
            } else if (eventData === "enableScan") {
                this.scanRef.inputMode = "text";
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    }

    componentDidUpdate() {
        this.requestFocus(5);
    }

    componentDidMount() {
        Logger.log.info("ScanView Mount");
        this.requestFocus(20);
        dolphinBus.addEventListener('message', this.eventListener);
        window.addEventListener('visibilitychange', ()=>this.onVisibilityChange(this));
        window.addEventListener('keydown', this.onWindowKeyDown);
    }

    componentWillUnmount() {
        Logger.log.info("ScanView Unmount");
        dolphinBus.removeEventListener('message', this.eventListener);
        window.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('keydown', this.onWindowKeyDown);
    }

    onWindowKeyDown(event) {
        if(event.key === "Tab") {
            Logger.log.info("Tab keyDown event received");
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onVisibilityChange(context) {
        if(document.visibilityState === "visible") {
            if(context.scanRef) {
                context.scanRef.readOnly = true;
            }
        }
    }

    onBlur = () => {
        this.requestFocus(20);
        Logger.log.info("onBlur");
    };

    getImageAlt = (primaryTitle, secondaryTitle) => {
        if(primaryTitle !== undefined && primaryTitle.type == "Bold") {
            return primaryTitle.title;
        }
        if(secondaryTitle !== undefined) {
            return secondaryTitle.title;
        }
        return this.props.intl.formatMessage({id: STRINGS.scan_barcode});
    };

    renderAlert = (tokenValidity) => {
        let message = "";
        let id = "";
        if (tokenValidity) {
            if (tokenValidity==="SUCCESS"){
                id = "LockedModuleLauncherView.Alert.success"
                message = "Your verification was successful. Remember to logout before the break."
            }
            else if (tokenValidity==="ALREADY_REGISTERED"){
                id = "LockedModuleLauncherView.Alert.alreadyRegistered"
                message = "Incorrect code. Ask manager to provide a new code."
            }
            else if (tokenValidity==="NOT_FOUND"){
                id = "LockedModuleLauncherView.Alert.notFound"
                message = "Incorrect code. Ask manager to provide a new code."
            }
            else {
                id = "LockedModuleLauncherView.Alert.failure"
                message = "Problem with connection. Ask manager to provide a new code."
            }
            const tokenStatus = tokenValidity === "SUCCESS" ? "success" : "error";
            return (<Alert type={tokenStatus}>
                <FormattedMessage
                id={id}
                defaultMessage={message}
            /></Alert>);
        }
        return null;
    };

    render() {
        const {primaryTitle, secondaryTitle, image, button, checkbox, onSubmit} = this.props;
        return (
            <Column height="100%" heights="fill">
                {this.renderAlert(this.props.tokenValidity)}
                <Column alignmentHorizontal="center">
                    {
                        (primaryTitle || secondaryTitle) &&
                        <HeaderView primaryTitle={primaryTitle} secondaryTitle={secondaryTitle}/>
                    }
                    {
                        image &&
                        <Row spacing={"none"} width="70%">
                            <img src={image} alt={this.getImageAlt(primaryTitle, secondaryTitle)} height="fit-content" style={{width: '100%'}} id='qr-scan' aria-hidden="true"/>
                        </Row>
                    }
                    {this.props.onSubmit &&
                        <div style={{zIndex: -1, position: "absolute", opacity: 0}}>
                            <input ref={(element) => {
                                this.scanRef = element;
                            }}
                                   autoFocus={true}
                                   autoComplete={"off"}
                                   readOnly={"readOnly"}
                                   type="text"
                                   onKeyDown={this.keyDown}
                                   inputMode={"text"}
                                   onBlur={this.onBlur}
                                   aria-labelledby="qr-scan"
                                   aria-label={this.props.ariaLabel}
                            />
                        </div>}
                    {checkbox &&
                        <Column alignmentHorizontal="left" width="100%" spacingInset="none none none medium">
                            <Checkbox checked={checkbox.checked} onChange={checkbox.onChange}>
                                {checkbox.label}
                            </Checkbox>
                        </Column>
                    }
                </Column>
                {button &&
                    <Column width="100%" alignmentVertical="bottom">
                        <Button onClick={button.onClick} size={button.size ? button.size : "large"} type={button.type ? button.type : "secondary"}>
                            {button.title}
                        </Button>
                    </Column>
                }
            </Column>
        )
    }
}


ScanTokenView.propTypes = {
    primaryTitle: PropTypes.shape({
        title: PropTypes.string,
        size: PropTypes.string,
        type: PropTypes.string
    }),
    secondaryTitle: PropTypes.shape({
        title: PropTypes.string,
        size: PropTypes.string,
        type: PropTypes.string
    }),
    image: PropTypes.string,
    button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    onSubmit: PropTypes.func,
    focusLock: PropTypes.bool,
    checkbox: PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    }),
    ariaLabel: PropTypes.string,
    tokenValidity: PropTypes.string
}

export default withRouter(injectIntl(ScanTokenView));
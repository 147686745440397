import KatalLogger, {Level} from "@amzn/katal-logger";
import DataHandler from "../handler/DataHandler";
import Constants from "../constants/Constants";
import DeviceDetailsHelper from "../helper/DeviceDetailsHelper";
import { v4 as uuidv4 } from 'uuid';

/**
 * Generate CloudWatch embedded metric format log which will be converted to CloudWatch metric
 * @param {*} nameSpace 
 * @param {*} metricName 
 * @param {*} metricValue 
 * @param {*} metricUnit 
 * @param {*} dimensions 
 * @returns 
 */
export const createEMFObject = (
    nameSpace,
    metricNameLabel,
    metricValue,
    metricUnit,
    dimensions = {},
  ) => {
    return {
      _aws: {
        Timestamp: Date.now(),
        CloudWatchMetrics: [
          {
            Namespace: nameSpace,
            Dimensions: [Object.keys(dimensions)],
            Metrics: [
              {
                Name: metricNameLabel,
                Unit: metricUnit,
              },
            ],
          },
        ],
      },
      ...dimensions,
      [metricNameLabel]: metricValue,
    };
  };

export default {
    log : null,
    async setLogger(loggerURL, stage, batchSize = 25) {
        this.log = new KatalLogger({
            url: loggerURL,
            decodeStackTrace : true,
            recordMetrics: false,
            maxLogLineSize:200000,
            maxLogsPerBatch: batchSize,
            context: {
                userId: DataHandler.getUserId(),
                country: DataHandler.getCountry(),
                deviceIMEI: DeviceDetailsHelper.getDeviceIMEI(),
                stationCode: DataHandler.getStationCode(),
                deviceChromeVersion: DeviceDetailsHelper.getChromeVersion(),
                dolphinSessionId: uuidv4(),
                version: window.location.pathname.split("/")[1]
            },
            logToConsole: (stage === Constants.Stage.BETA|| stage === Constants.Stage.GAMMA),
            logThreshold: Level.WARN
        });
        this.log.addErrorListener();
    },
    async getLogger() {
        return this.log;
    }
}
import {setSessionStorage, getSessionStorage} from "../utils/SessionStorageUtils";

const LOCATION_KEY = 'scanLocation';

export default {
    updateLastScannedLocation(location) {
        setSessionStorage(LOCATION_KEY, location);
    },

    getLastScannedLocation() {
        return getSessionStorage(LOCATION_KEY);
    }
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import bellTokens from "@amzn/meridian-tokens/base/icon/bell";
import Badge from "@amzn/meridian/badge";
import {
    API_ROUTES,
    MeshEvents,
    NativeMeshInteractor,
    getAssociateExperienceURL,
    FeatureManager, AssociateDashboardHelper, REDIRECT_HASH
} from "@amzn/dolphin-web-framework";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import {injectIntl} from "react-intl";
import {STRINGS} from "../../il8n/strings";
import Constants from "../HelpView/Constants";

const BG_COLOR = {
    'default': '#252829', // grey700
    'error': '#AD0A30', // red500
    'warning': '#FEF2B8' //
};

class TopNavigationView extends Component {

    constructor(props) {
        super(props);
        this.state = {notificationCount: 0};
    }

    onMenuButtonClicked = () => {
        if (this.props.history) {
            this.props.history.goBack();
        }
    }

    onNotificationBellIconClick = () => {
        if(this.props.stage && this.props.region) {
            AssociateDashboardHelper.redirectToAssociateDashboardModule(props.stage, props.region, Constants.PWA,
                {}, REDIRECT_HASH.ASSOCIATE_EXPERIENCE.DisplayNotificationRequest);
        }
    }

    onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.UPDATE_HELP_REQUEST_NOTIFICATION_BELL_STATUS) {
                const payload = eventData.payload;
                const notificationCount = Number(payload);
                this.setState({notificationCount});

            }
        } catch (JSONParseException) {
            console.error("Enable to parse event : " + event.data);
        }
    }

    componentDidMount() {
        this.dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
        this.dolphinBus.addEventListener('message', this.onMessage);

        // Get the notification bell status on load.
        // Call is made after 5 seconds to ensure that websocket connection is established
        // between PWA and native.
        setTimeout(
            function() {
                NativeMeshInteractor.getNotificationBellStatus();
            }, 5000);
    }

    componentWillUnmount() {
        this.dolphinBus.removeEventListener('message', this.onMessage);
    }

    renderNotificationBellIcon() {
        if (FeatureManager.isFeatureEnabled(FeatureManager.Features.NOTIFICATION_BELL_ENABLED)) {
            return (
                <Button label={'Notification'} type="icon" color={"inverted"} onClick={this.onNotificationBellIconClick}>
                    <Text color={"inverted"} alignment="end"><Icon tokens={bellTokens} >{this.state.notificationCount > 0 ? <Badge type={"error"}/> : null}</Icon></Text>
                </Button>
            )
        }
        return null;
    }

    render() {
        // preference to backgroundType if provided or fallback to backgroundColor
        const backgroundColor = BG_COLOR[this.props.backgroundType] || this.props.backgroundColor;
        return <Row backgroundColor={backgroundColor || BG_COLOR.default} width="100%" widths={["grid", "fill", "grid", "grid"]} spacingInset="small" spacing="none">
            <Button label={this.props.intl.formatMessage({id: STRINGS.menu_button})} type="icon" onClick={this.props.onMenuButtonClicked || this.onMenuButtonClicked}>
                <Text color={"inverted"}>
                    <Icon tokens={chevronLeftSmallTokens}/>
                </Text>
            </Button>
            <Text role="heading" type="h200" alignment="center" color={"inverted"} >{this.props.title}</Text>
            {this.renderNotificationBellIcon()}
            {
                this.props.optionsMenu.map((option, index) => {
                    return <Button label={option.label || `option ${index+1}`} key={index} type="icon" color={"inverted"} onClick={option.onClick}>
                        <Text color={"inverted"}><Icon tokens={option.icon}/></Text>
                    </Button>;
                })
            }
        </Row>;
    }
}

TopNavigationView.propTypes = {
    title: PropTypes.string.isRequired,
    onMenuButtonClicked: PropTypes.func,
    backgroundType: PropTypes.oneOf(['default', 'error']),
    backgroundColor: PropTypes.string,
    optionsMenu: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.object,
        label: PropTypes.string,
        onClick: PropTypes.func
    })),
    stage: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired
}

export default injectIntl(withRouter(TopNavigationView));
/**
 * Converts the current UTC timestamp (in milliseconds) to local time.
 * @param timestamp
 * @return timestamp converted to local time
 */
export function convertToLocalTime(timestamp) {

    // Gets the difference (in milliseconds) between the current time and UTC time (offset)
    const localTimeOffSetToUtc = new Date().getTimezoneOffset() * 60 * 1000;

    const date = new Date(timestamp);
    const convertedTime = new Date(date.getTime() - localTimeOffSetToUtc);
    
    return convertedTime;
}
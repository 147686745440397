export default {
    Stage : {
        BETA: 'beta',
        GAMMA: 'gamma',
        PROD: 'prod',
        INTEG: 'integ',
        TEST: 'test',
        MASTER: 'master'
    },
    Region : {
        REGION_NA: "NA",
        REGION_EU: "EU",
        REGION_FE: "FE",
        REGION_IN: "IN",
        REGION_UK: "UK",
        REGION_JP: "JP",
        REGION_US: "US",
    },
    AwsRegion : {
        NA: "us-east-1",
        EU: "eu-west-1",
        FE: "us-west-2",
        IN: "eu-west-1"
    },
    DolphinURL : {
        NA : {
            PROD: "https://dolphin.amazon.com",
            PREPROD: "https://dolphin-preprod.amazon.com"
        },
        EU : {
            PROD: "https://dolphin.amazon.eu",
            PREPROD: "https://dolphin-preprod.amazon.eu"
        },
        FE : {
            PROD: "https://dolphin-fe.amazon.com",
            PREPROD: "https://dolphin-fe-preprod.amazon.com"
        },
        IN : {
            PROD: "https://dolphin.amazon.eu/in",
            PREPROD: "https://dolphin-preprod.amazon.eu/in"
        }
    },
    AssociateExperienceURL : {
        NA : {
            BETA: "https://na-integ-aew.dolphinprod.last-mile.amazon.dev",
            GAMMA: "https://na-master-aew.dolphinprod.last-mile.amazon.dev",
            PROD: "https://na-prod-aew.dolphinprod.last-mile.amazon.dev"
        },
        EU : {
            BETA: "https://eu-integ-aew.dolphinprod.last-mile.amazon.dev",
            GAMMA: "https://eu-master-aew.dolphinprod.last-mile.amazon.dev",
            PROD: "https://eu-prod-aew.dolphinprod.last-mile.amazon.dev"
        },
        FE : {
            BETA: "https://fe-integ-aew.dolphinprod.last-mile.amazon.dev",
            GAMMA: "https://fe-master-aew.dolphinprod.last-mile.amazon.dev",
            PROD: "https://fe-prod-aew.dolphinprod.last-mile.amazon.dev"
        },
        IN : {
            BETA: "https://eu-integ-aew.dolphinprod.last-mile.amazon.dev",
            GAMMA: "https://eu-master-aew.dolphinprod.last-mile.amazon.dev",
            PROD: "https://eu-prod-aew.dolphinprod.last-mile.amazon.dev"
        }
    },
    ScanOverrideIdentityPoolId : {
        NA : {
            GAMMA: "us-east-1:f988c075-1fbc-4850-889b-c8503cb38b11",
            PROD: "us-east-1:3864539e-d1d2-42a0-aa03-0375623a6840"
        },
        EU : {
            GAMMA: "eu-west-1:a824abb9-e295-45f2-b1f4-0a3a6a12aecf",
            PROD: "eu-west-1:645f02d1-a27d-4733-9de6-2d895cad3a99"
        },
        FE : {
            GAMMA: "us-west-2:d3f34873-b447-4a6b-bf35-9b44b8bbceec",
            PROD: "us-west-2:31a24943-8c7e-4a89-8f98-290266c6f01b"
        },
        IN : {
            GAMMA: "eu-west-1:a824abb9-e295-45f2-b1f4-0a3a6a12aecf",
            PROD: "eu-west-1:645f02d1-a27d-4733-9de6-2d895cad3a99"
        }
    },
    RegionLowerCase: {
        REGION_NA: "na",
        REGION_EU: "eu",
        REGION_FE: "fe",
        REGION_IN: "in",
        REGION_JP: "jp"
    },
    ErrorCode: {
        DEVICE_OFFLINE : "DEVICE_OFFLINE",
        FALLBACK_LOGIN_DISABLED_ERROR : "FALLBACK_LOGIN_DISABLED_ERROR",
    },
    HTTPRequestMethods: {
        GET_METHOD : "get",
        POST_METHOD : "post"
    }
}
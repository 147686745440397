import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import PropTypes from "prop-types";
import HeaderView from "../HeaderView";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";

/*
A grid like view to display a heading and subheading in the subgrid, it has many customizable features with and support to
add more. It is ensured that all the features are in separate divisions to allow addition of more features if required.

Attributes:

title: Title of the screen appearing at the top. It will be in bold letters with text type h300

description: Description will appear in the b300 Text type just below the Title.

gridTitle: Title at the top of the grid

dataSnapshots: dataSnapshots will be array of details which will be displayed in each of the subgrid the custom grid
view supports backwards compatible addition of items in dataSnapshots like - icon and background color which has been
added.

onClick: A link on which user should be redirected, if clicked on the subgrid.

dimension: To configure the number of columns in the grid .It is a required attribute.
 */

class CustomGridView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSector: null
        };
    }

    onClick = (data) => {
        this.setState({selectedSector: data});
        if(this.props.onClick) {
            this.props.onClick(data);
        }
    };

    uiItems = (dataSnapshots, dimension) => {
        let uiItems = [], temporaryRow = null, keyIndex = 0;

        for (const dataSnapshot of dataSnapshots) {
            temporaryRow = temporaryRow || [];
            temporaryRow.push(<Box key={dataSnapshot.title} type={this.state.selectedSector && this.state.selectedSector === dataSnapshot.title ? "fill" : "outline"}
                                   backgroundColor={dataSnapshot.color? dataSnapshot.color: ""} spacing="none">
                <Column spacing="none">
                    <div tabIndex="0" role="button" onClick={() => this.onClick(dataSnapshot.title)} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{paddingLeft: '2%', paddingTop: '5%', paddingBottom: '5%'}}>
                            {Object.keys(dataSnapshot.icon).length > 0 && (<Icon tokens={dataSnapshot.icon}/>)}
                        </div>
                        <div style={{textAlign: 'center', flex: 1, paddingRight: Object.keys(dataSnapshot.icon).length !== 0 ? '7.5%' : 0,
                            paddingTop: '5%', paddingBottom: '5%'}}>
                            <Text type="h200" alignment="center">{dataSnapshot.title}</Text>
                            <Text type="b200" alignment="center">{dataSnapshot.description}</Text>
                        </div>
                    </div>
                </Column>
            </Box>);
            if (temporaryRow.length === dimension) {
                uiItems.push(<Row key={keyIndex++} spacing={"none"}
                                  widths={Array(dimension).fill("grid-".concat((12 / dimension).toString()))}>{temporaryRow}
                </Row>);
                temporaryRow = null;
            }
        }
        // adding any trailing entries
        temporaryRow && uiItems.push(<Row key={keyIndex++} spacing={"none"}
                                          widths={Array(dimension).fill("grid-".concat((12 / dimension).toString()))}>{temporaryRow}
        </Row>);
        return uiItems
    }

    render() {
        const {title, description, gridTitle, dataSnapshots, dimension} = this.props
        const uiItems = this.uiItems(dataSnapshots, dimension)
        const headerData = {
            primaryTitle: {
                title: title,
                type: "Bold",
                size: "Medium"
            },
            secondaryTitle: {
                title: description,
                type: "Simple",
                size: "Medium"
            }
        }
        return (
            <Column>
                <Column>
                    {(title || description) && <HeaderView {...headerData}/>}
                    <Column spacing="none">
                        <Box width="100%" type="outline" spacing="none">
                            {gridTitle &&
                                <Column backgroundColor={"#2B3648"}>
                                <div style={{paddingTop: '3%', paddingBottom: '3%'}}>
                                <Text type="h300" alignment="center" color="inverted">
                                    {gridTitle}
                                </Text>
                                </div>
                            </Column>}
                            {uiItems}
                        </Box>
                    </Column>
                </Column>
            </Column>
        )
    }
}

CustomGridView.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    gridTitle: PropTypes.string,
    dataSnapshots: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        color: PropTypes.string,
        icon: PropTypes.object
        // add more customization fields here for each snapshot (ensure to add it in a separate division)
    })).isRequired,
    dimension: PropTypes.number.isRequired,
    onClick: PropTypes.func
}

export default CustomGridView;
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import Divider from "@amzn/meridian/divider";

const BG_COLOR = {
    'default': '#ffffff', // white
    'disable': '#808080', // grey
};

class AccordionView extends Component {

    onClick = () => {
        if (this.props.isDisabledView) {
            return;
        } else if (this.props.onClick) {
            this.props.onClick.call();
        } else {
            if (this.props.history) {
                this.props.history.push(this.props.href);
            } else if (window) {
                window.location.href = this.props.href;
            }
        }
    };

    getBackgroundColor = () => {
        if (this.props.isDisabledView) {
            return BG_COLOR["disable"];
        } else {
            return BG_COLOR["default"];
        }
    };

    getTitleTextType = () => {
        if (this.props.titleFontWeight) {
            switch (this.props.titleFontWeight) {
                case "Normal":
                    return "b300";
                default:
                    return "h100";
            }
        } else {
            return "h100"
        }
    };

    render() {
        const {title, description, titleColor, descriptionColor} = this.props
        return (
            <div tabIndex="0" role="button" onClick={this.onClick} style={{cursor: "pointer"}}>
                <Column spacingInset="medium" spacing="small" backgroundColor={this.getBackgroundColor()}>
                    <Row alignmentHorizontal="justify">
                        <Text type={this.getTitleTextType()} color={titleColor}>{title}</Text>
                        <Text><Icon tokens={chevronRightSmallTokens}/></Text>
                    </Row>
                    {description &&
                    <Text type="b200" color={descriptionColor}>{description}</Text>
                    }
                </Column>
                <Divider size={"small"} />
            </div>
        )
    }
}

export const AccordionViewPropTypes = {
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
    titleFontWeight: PropTypes.string,
    description: PropTypes.string,
    descriptionColor: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    isDisabledView: PropTypes.bool
};

AccordionView.propTypes = AccordionViewPropTypes;

export default withRouter(AccordionView);
//stora a key in session storage
export const setSessionStorage = (key, value) => {
    try {
        sessionStorage.setItem(key, value);
    } catch (error) {
        console.error(error)
    }

}

//get a item from session storage
export const getSessionStorage = (key) => {
    try {
        return sessionStorage.getItem(key);
    } catch (error) {
        console.error(error)
        return '';
    }
}


import {CommonModules} from "../constants/CommonModules";
import {getAssociateExperienceBaseURL, REDIRECT_HASH} from "../utils/BaseURLUtils";
import {RedirectHandler} from "../index";

export default {
    /**
     * Redirects the user to dashboard summary page.
     * @param stage current stage
     * @param region current region
     * @param fromModule Dolphin PWA module from where request is being redirected.
     * @param redirectData Data to be sent to dashboard (optional).
     * @returns {*}
     */
    async redirectToSummaryView(stage, region, fromModule, redirectData) {
        const invokeDashboardModule = async () => {
            const associateDashboardUrl = new URL(getAssociateExperienceBaseURL(stage, region));
            RedirectHandler.invokeModule(fromModule, CommonModules.ASSOCIATE_DASHBOARD, associateDashboardUrl.host,
                REDIRECT_HASH.ASSOCIATE_EXPERIENCE.InsightSummaryRequest, redirectData, null, null,
                associateDashboardUrl.pathname, null);
        };

        await invokeDashboardModule();
    },

    /**
     * Redirects the user to 'Associate Dashboard' module.
     * @param stage current stage
     * @param region current region
     * @param fromModule Dolphin PWA module from where request is being redirected.
     * @param redirectData Data to be sent to dashboard module (optional).
     * @param redirectHash redirect path of the module (optional). Defaulted to home page.
     * @returns {*}
     */
    async redirectToAssociateDashboardModule(stage, region, fromModule, redirectData, redirectHash) {
        const invokeDashboardModule = async () => {
            const associateDashboardUrl = new URL(getAssociateExperienceBaseURL(stage, region));
            RedirectHandler.invokeModule(fromModule, CommonModules.ASSOCIATE_DASHBOARD, associateDashboardUrl.host,
                redirectHash ? redirectHash : REDIRECT_HASH.ASSOCIATE_EXPERIENCE.DisplayNotificationRequest,
                redirectData, null, null, associateDashboardUrl.pathname, null);
        };

        await invokeDashboardModule();
    }
}
import axios from "axios";
import {nativeInteractorInterceptorBuilder} from "./NativeInteractorInterceptor";

const SERVER_BASE = "http://localhost:8989";
const nativeAPIClient = axios;
const interceptor = nativeInteractorInterceptorBuilder(axios);
nativeAPIClient.interceptors.request.use(interceptor.onBeforeRequest, interceptor.onRequestError);
nativeAPIClient.interceptors.response.use(interceptor.onResponseSuccess, interceptor.onResponseError);

export default {

    poll: false,

    /**
     * Open Home activity and close this TWA
     * @returns {Promise<*>}
     */
    async closePWA() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/home`);
        return response.data;
    },

    /**
     * Show no Internet Dialog
     * @returns {Promise<*>}
     */
    async noInternet() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/no-internet`);
        return response.data;
    },

    async fetchAccessToken() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/getAccessToken`);
        return response.data;
    },

    /**
     * Play sound from native sound manager
     * @param resourceName
     * @returns {Promise<*>}
     */
    async playSound(resourceName) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/sound/play?resource=${resourceName}`);
        return response.data;
    },

    /**
     * Play success sound from native sound manager
     * @returns {Promise<*>}
     */
    async playSuccessSound() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/sound/success`);
        return response.data;
    },

    /**
     * Play success sound from native sound manager
     * @returns {Promise<*>}
     */
    async playWarningSound() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/sound/warning`);
        return response.data;
    },

    /**
     * Play success sound from native sound manager
     * @returns {Promise<*>}
     */
    async playErrorSound() {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/sound/error`);
        return response.data;
    },


    /**
     * Poll scan value from native
     * @param callback
     */
    async pollAveryScanValue(callback) {
        try {
            this.cancelTokenSource = nativeAPIClient.CancelToken.source();
            const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/scan`, {
                cancelToken: this.cancelTokenSource.token
            });
            if (this.poll) {
                if (response.data && response.data.avery_scan) callback(response.data.avery_scan);
                setTimeout(() => this.pollAveryScanValue(callback), 20);
            }
        } catch (e) {
            if (this.poll) setTimeout(() => this.pollAveryScanValue(callback), 20);
        }
    },

    async startPollingAveryScanValue(callback) {
        this.poll = true;
        await this.pollAveryScanValue(callback);
    },

    /**
     * Stop polling for scan value
     */
    stopPollingAveryScanValue() {
        this.poll = false;
        this.cancelTokenSource.cancel();
    },


    /**
     * Returns connection status of avery
     * @param input
     * @returns {Promise<*>}
     */
    async averyInfo(input) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/info`);
        return response;
    },

    /**
     * Print using Avery Printer
     * @param input
     * @returns {Promise<*>}
     */
    async print(input) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/print?input=${input}`);
        return response.data;
    },

    /**
     * Print using Avery Printer with tag
     * @param input
     * @param tag
     * @returns {Promise<*>}
     */
    async printWithTag(input, tag) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/print?input=${input}&tag=${tag}`);
        return response.data;
    },

    async sendAPICallAnalytics(module, duration, failure) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/api/analytics?module=${module}&duration=${duration}&failure=${failure}`);
    },

    async sendModuleOperationAnalytics(event, module, description, description1, duration, scanContent) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/process/analytics?event=${event}&module=${module}&description=${description}&
        description1=${description1}&duration=${duration}&scanContent=${scanContent}`);
    },

    /**
     * Change Avery Printer
     * @param printerId
     * @returns {Promise<*>}
     */
    async changePrinter(printerId) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/change-printer?printer_id=${printerId}`);
        return response.data;
    },

    /**
     * Set Sal Format for Avery Printer
     * @param salFormat
     * @param labelTag
     * @returns {Promise<void>}
     */
    async setSalFormat(salFormat, labelTag) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/avery/set-format?format=${salFormat}&label_tag=${labelTag}`);
        return response.data
    },

    /**
     * Returns launching endpoint for respective PWA module with required app data in URL params.
     *
     * @param moduleName corresponding to endpoint name in
     * https://code.amazon.com/packages/StationBuddy/blobs/mainline/--/src/main/res/xml/net_conf.xml
     */
    async getModuleLauncherRoute(moduleName) {
        const response = await nativeAPIClient.get(`${SERVER_BASE}/getModuleLauncherRoute?module_name=${moduleName}`);
        return response.data;
    },

    /**
     * Returns connection and resolution info of zebra printer.
     */
    async zebraInfo() {
        const url = `${SERVER_BASE}/zebra/info`;
        const response = await nativeAPIClient.get(url);
        return response.data;
    },

    /**
     * Prints label via zebra printer
     * label = ZPL encoded string
     */
    async zebraPrintLabel(label) {
        const url = `${SERVER_BASE}/zebra/print?label=${label}`;
        const response = await nativeAPIClient.get(url);
        return response.data;
    },

    /**
     * Connectes device to zebra printer
     * mac_address: mac address of zebra printer
     */
    async zebraConnectPrinter(mac_address) {
        const url = `${SERVER_BASE}/zebra/connect?mac_address=${mac_address}`;
        const response = await nativeAPIClient.get(url);
        return response.data;
    },

    /**
     * Closes connection between device and zebra printer
     */
    async zebraClosePrinter() {
        const url = `${SERVER_BASE}/zebra/close`;
        const response = await nativeAPIClient.get(url);
        return response.data;
    },
};

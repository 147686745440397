export default {
    MESSAGE: "message",
    MESSAGE_BODY: "messageBody",
    TITLE: "title",
    REDIRECT_LINK: "redirectLink",
    NOTIFICATION_ID: "notificationId",
    PRIORITY: "priority",
    NOTIFICATION_TIMEOUT: 1000 * 30, //30 seconds.
    SHOW_ME_MORE: "Show me more",
    DELIVERED: "Delivered"
}
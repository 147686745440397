import DataHandler from "./DataHandler";
import UAASAccessor from "../accessor/UAASAccessor";

export default {
    Features : {
        VOLUMETRIC_SCAN: "VolumetricScan",
        INDUCT_LABEL_PRINTING: "InductLabelPrinting",
        AISLE_FREE_PACKAGE_PICKING: "AisleFreePackagePicking",
        PICKING_MULTIPLE_BAG: "PickingMultipleBag",
        PICKING_MULTIPLE_BAG_WITH_SETTING: "PickingMultipleBagWithSetting",
        DECOUPLED_PICK_STAGE: "DecoupledPickStage",
        MANUAL_TRACKING_ID_HELP_ENABLED: "ManualTrackingIdHelpEnabled",
        RTS_OVERRIDE_REMOVAL_ENABLED: "RTSOverrideRemovalEnabled",
        IMEI_RETRY_ATTEMPT_ENABLED: "ImeiRetryAttemptEnabled",
        STORE_DEBRIEF_ENABLED: "StoreDebriefEnabled",
        PHONE_NUMBER_SEARCH_ENABLED: "PhoneNumberSearchEnabled",
        RECEIVE_ENABLED: "ReceiveEnabled",
        CONTAINER_MISSORT_RECEIVE_ENABLED: "ContainerMissortReceiveEnabled",
        CONTAINER_PRIORITIZATION_RECEIVE_ENABLED: "ContainerPrioritizationReceiveEnabled",
        PUBLISH_RECEIVE_CONTAINER_SCAN_V2_ANALYTICS: "PUBLISH_RECEIVE_CONTAINER_SCAN_V2_ANALYTICS",
        RTS_HIGH_VALUE_PACKAGE_WARNING: "RTSHighValuePackageWarning",
        RTS_RESCHEDULE_VISIBILITY: "RTSRescheduleVisibility",
        RTS_FIXIT_INTEGRATION: "RTSFixitIntegration",
        RTFC_PACKAGE_DEPART: "RTFCPackageDepart",
        HIDE_REQUEST_STAGE_LIST: "HideRequestTaskList",
        ASSIGN_TO_STAGE: "AssignToStage",
        SCAN_TO_STAGE: "ScanToStage",
        MIXED_CART_FLASH_SCREEN: "MixedCartFlashScreen",
        LAST_CART_FLASH_SCREEN: "LastCartFlashScreen",
        STAGE_SERVICE_ENABLED: "StageServiceEnabled",
        PICK_ITEM_SCAN_EVENT_ENABLED:"PickItemScanEventEnabled",
        PICKING_FOR_ELIGIBLE_NODE:"PickingForEligibleNode",
        HIGH_SPR_ENABLED_AT_STATION:"HighSprEnabledAtStation",
        SCHEDULED_DELIVERY_DISPLAY_ENABLED: "ScheduledDeliveryDisplayEnabled",
        PICK_OV_BY_SORT_ZONE_ENABLED: "PickOVBySortZoneEnabled",
        AISLE_CHANGE_INDICATOR_ENABLED: "AisleChangeIndicatorEnabled",
        PARALLEL_PICK: "ParallelPick",
        SHOW_WAVE_NUMBER: "ShowWaveNumber",
        TOTAL_PICKLISTS_ENABLED: "TotalPicklistsEnabled",
        MANUAL_ASSIGNMENT_DISABLED: "ManualAssignmentDisabled",
        AUTO_ASSIGNMENT_DISABLED: "AutoAssignmentDisabled",
        AVERY_HOTSWAP_INDUCT: "AveryHotswapInduct",
        AVERY_HOTSWAP_STOW: "AveryHotswapStow",
        DRONA_ENABLED: "DronaEnabled",
        CART_HOT_SWAP: "CartHotSwapV3",
        STAGER_ATTACH_EMPTY_CART:"StagerAttachEmptyCart",
        STAGER_RESET_EMPTY_CART:"StagerResetEmptyCart",
        SKIP_PRINTER_DISCONNECTION: "SkipPrinterDisconnection",
        OV_SCAN_TO_BAG_BLOCK: "OvScanToBagBlock",
        OV_RACK_LABEL_V2_ENABLED: "OVRackLabelV2Enabled",
        CROSS_STOW_ENABLED: "crossStowEnabled",
        SEARCH_TRANSPORTER_ONLY_BY_BADGE_SCAN_ENABLED: "SearchTransporterOnlyByBadgeScanEnabled",
        SHOW_CART_SEQUENCE: "showCartSequence",
        ONLY_DEPART_ENABLED: "onlyDepartEnabled",
        MOBILE_PRINTER_ENABLED: "MobilePrinterEnabled",
        AR_NEXT_GEN_PRINT_SAL_AT_PICK_ENABLED: "ARNextGenPrintSALAtPickEnabled",
        AVERY_THROTTLE_ENABLED: "AveryThrottleEnabled",
        OPEN_BAG_THROTTLE_ENABLED: "OpenBagThrottleEnabled",
        CASH_DEBRIEF_ENABLED: "CashDebriefEnabled",
        CDM_FLOW_ENABLED: "CDMFlowEnabled",
        BUYBACK_TO_REGULAR_ENABLED: "BuybackToRegularEnabled",
        CASH_PAYMENT_OPTIONS_ENABLED: "CashPaymentOptionsEnabled",
        SAL_DM_CODE_SCAN: "EnableSalDmCodeScan",
        COD_RUNNING_BALANCE_DETAIL_ENABLED: "CODRunningBalanceDetailEnabled",
        PICK_DISPLAY_SAL_COLOR_INFO: "PickDisplaySalColorInfo",
        DRIVER_OVERBOOKING_ENABLED: "DriverOverbookingEnabled",
        SAM_V1_ENABLED: "IsSAMV1Enabled",
        STOW_SAL_COLOR_MISMATCH: "StowSalColorMismatchOption",
        HEAVY_BAGS_ALERTS_ENABLED: "HeavyBagsAlertsEnabled",
        SCANLESS_STOW_SOUND_SYNC: "ScanlessStowSoundSync",
        ENFORCE_CORRECT_ORDER_BAG_SCAN: "EnforceCorrectOrderBagScan",
        SPLIT_STAGING_RAMP_WORKFLOW_ENABLED: "SplitStagingRampEnabled",
        SPLIT_STAGING_AUTOLATOR_WORKFLOW_ENABLED: "SplitStagingAutolatorEnabled",
        SAL_COLOR_AUDIT: "SalColorAudit",
        SHOW_SWA_SPECIFIC_PRINT_LABEL_TEXT: "ShowSWASpecificPrintLabelText",
        SWA_UNDELIVERABLE_RTO_ACTIONS_DISABLED: "SWAUndeliverableRTOActionsDisabled",
        SWA_UNDELIVERABLE_CATEGORY_DISABLED: "SWAUndeliverableCategoryDisabled",
        SWA_SINGLE_SHIPMENT_CATEGORY_BULK_SOLVING: "SWASingleShipmentCategoryBulkSolving",
        SCRUB_CONTAINER_BEFORE_ATTACH_ENABLED: "ScrubContainerBeforeAttachEnabled",
        SAL_CAPTURE_ENABLED: "SalCaptureEnabled",
        WRONG_PLANNED_STATION_RTO_DISABLED:"WrongPlannedStationRTODisabled",
        SCANLESS_V2_ENABLED: "ScanlessV2Enabled",
        HASH_ROUTER_ENABLED: "HashRouterEnabled",
        NSS_NAWS_ENDPOINTS_ENABLED: "NssNAWSEndpointsEnabled",
        SBL_DISPLAY_COLOR_ENABLED: "SBLDisplayColorEnabled",
        DRIVER_QR_CODE_SCAN_REQUIRED: "DriverQRCodeScanRequired",
        IS_RECEIVE_PACKAGE_V2_WORKFLOW_ENABLED_FOR_IN: "IsReceivePackageV2EnabledForIN",
        IS_PSEW_NNS_FLOW_ENABLED: "IsPsewNnsFlowEnabled",
        AUTO_CONNECT_TO_PREVIOUS_PRINTER: "AutoConnectToPreviousPrinter",
        PRINT_LABEL_TWICE: "PrintLabelTwice",
        SSD_3_ENABLED: "SSD3Enabled",
        IS_STATION_LIST_NNS_FLOW_ENABLED: "IsStationListNnsFlowEnabled",
        DAISY_CHAIN_INDUCT_FLOW_ENABLED: "IsDaisyChainInductFlowEnabled",
        CAS_COLOR_SENSE_ENABLED: "CasColorSenseEnabled",
        DISABLE_RTO_FOR_MISSING_PS: "DisableRTOActionForMissing",
        IS_FEATURE_VALUE_NNS_ENABLED: "IsFeatureValueNnsEnabled",
        IS_PACKAGE_LABEL_NNS_ENABLED: "IsPackageLabelNnsEnabled",
        SHOULD_CAMERA_OPEN: "shouldCameraOpen",
        IS_PARTIAL_DELIVERY_ENABLED: "IsPartialDeliveryEnabled",
        IS_CATEGORIES_NNS_ENABLED: "IsCategoriesNnsEnabled",
        IS_SHIPMENTS_BY_SCANNABLE_NNS_ENABLED: "IsShipmentsByScannableNnsEnabled",
        IS_SEARCH_TRANSPORTER_NNS_ENABLED: "IsSearchTransporterNnsEnabled",
        IS_STAGING_AREA_FOR_PICKLIST_NNS_ENABLED: "IsStagingAreaForPicklistNnsEnabled",
        IS_GET_EXCEPTION_SHIPMENT_SUMMARY_NNS_ENABLED: "IsGetExceptionShipmentSummaryNnsEnabled",
        IS_LOOSE_ITEM_WORKFLOW_ENABLED: "IsLooseItemWorkflowEnabled",
        IS_ML_RESTOW_ENABLED: "IsMLRestowEnabled",
        SHOW_CONTAINER_CLOSE_FAILED_PACKAGE_DETAILS: "ShowContainerCloseFailedPackageDetails",
        SHOW_AUDITING_TASK: "ShowAuditingTask",
        IS_INDUCT_DWELLING_ACTIONS_DISABLED: "IsInductDwellingActionsDisabled",
        IS_REDIRECT_TO_PS_ENABLED: "IsRedirectToPSEnabled",
        IS_REDIRECT_TO_PS_ENABLED_FOR_NEXT_GEN: "IsRedirectToPSEnabledForNextGen",
        DISABLE_BACK_BUTTON_FOR_MISSORTS: "disableBackButtonForMissorts",
        RECEIVE_EXCEPTION_QUEUE_VISIBILITY_ENABLED: "ReceiveExceptionQueueVisibilityInPSEnabled",
        IS_CONTAINER_MISSORT_FLOW_ENABLED: "IsContainerMissortFlowEnabled",
        IS_GET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS_NNS_ENABLED: "IsGetExceptionShipmentSummaryDetailsNnsEnabled",
        IS_GET_EXCEPTION_SHIPMENT_SUMMARY_WITHOUT_SHIP_DETAILS_NNS_ENABLED: "IsGetExceptionShipmentSummaryWithoutShipDetailsNnsEnabled",
        IS_UPDATE_EXCEPTION_SHIPMENTS_NNS_ENABLED: "IsUpdateExceptionShipmentsNnsEnabled",
        IS_UPDATE_EXCEPTION_SHIPMENTS_FOR_MULTIPLE_CATEGORY_NNS_ENABLED: "IsUpdateExceptionShipmentsForMultipleProblemCategoryNnsEnabled",
        IS_UPDATE_EXCEPTION_TRANSPORTER_NNS_ENABLED: "IsUpdateExceptionTransporterNnsEnabled",
        IS_LOOSE_ITEM_SOLVE_LATER_WORKFLOW_ENABLED: "isLooseItemSolveLaterWorkflowEnabled",
        REGULAR_STOW_DISABLED: "isRegularStowDisabled",
        NOTIFICATION_BELL_ENABLED: "NotificationBellEnabled",
        CREATE_HELP_REQUEST_ENABLED: "CreateHelpRequestEnabled",
        IS_STOW_LABOR_ALLOCATION_ENABLED: "isStowLaborAllocationEnabled",
        PACKAGE_MISSORT_CONTAINER_ID_MIGRATION_ENABLED : "PackageMissortContainerIdMigrationEnabled",
        GET_STAGING_AREA_WITHOUT_PICKLIST_ID: "getStagingAreaWithoutPicklistId",
        ENABLE_STAGE_IN_PICK_LEFT_BEHIND: "EnableStageInPickLeftBehind",
        DISABLE_BACK_BUTTON_DURING_UPDATE_FOR_MISSORTS : "DisableBackButtonDuringUpdateForMissorts",
        IS_INDUCT_SIDELINE_UNTIL_TIME_ENABLED: "IsInductSidelineUntilTimeEnabled",
        SHOW_PACKAGE_TYPE_AND_SIZE: "ShowPackageTypeAndSizeEnabled",
        DAMAGE_IMAGE_ANALYSIS_ENABLED: "DamageImageAnalysisEnabled",
        DEPART_BY_DRIVER_ENABLED: "isDepartByDriverEnabled",
        DEPART_PALLET_DISABLED: "isDepartPalletDisabled",
        BYPASS_VEHICLENUMBER_VALIDATION: "bypassVehicleNumberValidation",
        DISABLE_BACK_BUTTON_DURING_MARK_AS_PROBLEM: "DisableBackButtonDuringMarkAsProblem",
        BLOCK_RECEIVED_PACKAGE_TOP_UP: "BlockReceivedPackageTopup",
        STAG_ENABLED: "STAGEnabled",
        INDUCT_CHANGE_PRINTER_ALLOWED: "InductChangePrinterAllowed",
        RTS_VERIFICATION_IMAGE_RESIZE_ENABLED: "RTSVerificationImageResizeEnabled",
        IS_DO_NOT_ALLOW_TO_EXIT_STOW_FLOW_IN_MID_WAY: "IsDoNotAllowToExitStowFlowInMidWay",
        IS_BLOCKING_STOW_FLOW_ENABLED: "IsBlockingStowFlowEnabled",
        ENABLE_ASYNC_ZEBRA_PRINTING: "EnableAsyncZebraPrinting",
        IS_PROBLEM_SOLVE_REARCHITECTURE_ENABLED : "IsProblemSolveRearchitectureEnabled",
        IS_PS_CATEGORY_ACTION_DISABLED : "IsPSCategoryActionDisabled",
        IS_2SV_PS_ENABLED: "is2SVPSEnabled",
        IS_2SV_DEPART_ENABLED: "is2SVDepartEnabled",
        IS_2SV_RTS_ENABLED: "is2SVRTSEnabled",
        PLAY_AUDIO_NOTIFICATION_FROM_NATIVE: "PlayAudioNotificationFromNative",
        RECEIVE_PACKAGES_DISABLED: "ReceivePackagesDisabled",
        CROSS_STOW_EVENT_ENABLED_FOR_THIN_CLIENT: "crossStowEventEnabledForThinClient",
        DISPLAY_STOW_PROCESS_METRIC: "DisplayStowProcessMetric",
        DISPLAY_STOW_SUMMARY_METRIC: "DisplayStowSummaryMetric",
        DISPLAY_INDUCT_PROCESS_METRIC: "DisplayInductProcessMetric",
        DISPLAY_INDUCT_SUMMARY_METRIC: "DisplayInductSummaryMetric",
        SEND_IOT_AUTOMATION_WS_PING: "SendIoTAutomationWSPing",
        IS_PICK_STATION_PROCESS_ENABLED : "IsPickStationProcessEnabled",
        DOLPHIN_SCAN_OVERRIDE_ENABLED: "DolphinScanOverrideEnabled",
        PROBLEM_SOLVE_SCAN_OVERRIDE_ENABLED: "ProblemSolveScanOverrideEnabled",
        DP_CHECKIN_ENABLED: "DPCheckinEnabled",
        DROP_TO_CART_ENABLED: "DropToCartEnabled",
        DISPLAY_SHELF_ENABLED: "DisplayShelfEnabled",
        EXIT_STOW_MODULE_ON_REDIRECTION_DISABLED: "ExitStowModuleOnRedirectionDisabled",
        DAMAGE_REVAMPED_WORKFLOW_ENABLED: "DamageRevampedWorkflowEnabled",
        SHOW_PKG_SCAN_NOTIFICATION_DISABLED: "ShowPkgScanNotificationDisabled",
        STAG_CART_RESET_ENABLED: "STAGResetCartEnabled",
        ROUTE_TRAFFIC_TO_PILOT_STACK_ENABLED: "RouteTrafficToPilotStackEnabled",
        IS_HELP_OPTIONS_FOR_DEFAULT_SCOPE_ENABLED: "isHelpOptionsForDefaultScopeEnabled",
        IS_MEDIA_CAPTURE_FLOW_ENABLED: "IsMediaCaptureFlowEnabled",
        GROCERY_PICK_WORKGROUP_TYPE_ENABLED: "isGroceryWorkGroupTypeEnabled",
        IS_BLOCKING_STOW_LOOP_HOLES_FIX: "IsBlockingStowLoopHolesFix",
        DISPLAY_ALL_PLANNED_STAGING_AREAS: "DisplayAllPlannedStagingAreas",
        HIGH_CONFIDENCE_SUPPLY_CHAIN_ENABLED: "HighConfidenceSupplyChainEnabled",
        ADVANCE_VERIFICATION_ENABLED: "AdvanceVerificationEnabled",
        IS_AUTO_PICKLIST_ASSIGNMENT_ENABLED: "IsAutoPicklistAssignmentEnabled",
        SCAN_FROM_OVERVIEW_FOR_MANAGER_APPROVAL: "IsScanFromOverviewForManagerApprovalEnabled",
        SHOW_ASIN_RESOLUTION_SCREEN_FOR_DAMAGED_PACKAGE: "ShowAsinResolutionScreenForDamagedPackage",
        AUTO_CONNECT_TO_PRINTER_FOR_DAMAGED_LABEL_ENABLED: "AutoConnectToPrinterForDamagedLabelEnabled",
        CART_REPLENISHMENT_WORKFLOW_REVERSE: "CartReplenishmentWorkflowReverse",
        REVERSE_WORKFLOW_ENABLED: "IsReverseWorkflowEnabled",
        IS_ENABLED_HANDLING_SCRUB_MIS_USE: "IsEnabledHandlingScrubMisUse",
        IS_ENABLED_HELP_OPTION_WITH_DESCRIPTION: "isEnabledHelpOptionWithDescription",
        LOCAL_ZONE_PILOT_ENABLED: "IsLocalZonePilotEnabled",
        DG_BAG_LABELING_ENABLED_FOR_PICK: "DGBagLabelingEnabledForPick",
    },

    isFeatureEnabled(featureName) {
        return DataHandler.userFeatures.includes(featureName);
    },

    isFeatureNotEnabled(featureName) {
        return !DataHandler.userFeatures.includes(featureName);
    },

    /**
     * Retrieves G2S2 features enabled in given stage and region
     * from UserAccountAndAttributesService
     * @param stage
     * @param region
     * @returns {Promise<void>}
     */
    async getFeatures(stage, region) {
        //If features are already retrieved skip this call.
        if (DataHandler.userFeatures.length !== 0) {
            return;
        }
        const modulesAndFeatures = await UAASAccessor.getModulesAndFeatures(stage, region);
        DataHandler.setUserFeatures(modulesAndFeatures.features.map((feature) => {
            return feature.featureName
        }))

        //populating modules training status
        if (modulesAndFeatures.modulesWithTrainingStatus) {
            DataHandler.setModulesTrainingStatus(modulesAndFeatures.modulesWithTrainingStatus);
        }

        //populating pwa module versions
        if(modulesAndFeatures.moduleVersionMap) {
            DataHandler.setModuleVersions(modulesAndFeatures.moduleVersionMap);
        }
    }
};

import React, {useEffect, useState} from "react";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";
import TextView from "../TextView";
import Button from "@amzn/meridian/button";
import "./helpRequestNotificationView.css";
import {MeshEvents, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import Constants from "./Constants";

const HelpRequestNotificationView = () => {

    const [toasts, setToasts] = useState([]);

    const onCloseToast = (id) => {
        const notificationObj = getNotificationObjFromId(id);
        // If notification object does not have redirectLink, then update its status.
        if (notificationObj && !notificationObj[Constants.REDIRECT_LINK]) {
            NativeMeshInteractor.updateNotificationStatus(id, Constants.DELIVERED, notificationObj[Constants.PRIORITY]);
        }
        removeToast(id);
    }

    const getNotificationObjFromId = (id) => {
        const notificationObjArray = toasts.filter(t => t.id === id);
        if (notificationObjArray.length === 0) {
            console.error("Notification with id=", id, " not found for update.");
            return ;
        }
        return notificationObjArray[0];
    }

    const redirectFromNotification = (id, redirectLink) => {
        const notificationObj = getNotificationObjFromId(id);
        if (notificationObj) {
            NativeMeshInteractor.updateNotificationStatus(id, Constants.DELIVERED, notificationObj[Constants.PRIORITY]);
        }
        removeToast(id);
        window.location.href = redirectLink;
    }

    const removeToast = (id) => {
        setToasts(prevState => {
            return prevState.filter(t => t.id !== id);
        });
    }

    const addNewToast = (notificationData, redirectLink) => {
        setToasts(prevState => {
            return [...prevState, {id: notificationData[Constants.NOTIFICATION_ID], timeout: Constants.NOTIFICATION_TIMEOUT, ...notificationData, redirectLink }];
        });
    }

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.HELP_REQUEST_NOTIFICATION_RECEIVED) {
                const payload = JSON.parse(eventData.payload);
                const notificationDataPayload = JSON.parse(payload.notificationData);

                addNewToast(notificationDataPayload, payload[Constants.REDIRECT_LINK]);
            }
        } catch (JSONParseException) {
            console.error("Enable to parse event : " + event.data);
        }
    }

    useEffect(() => {
        const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
        dolphinBus.addEventListener('message', onMessage);

        return () => dolphinBus.removeEventListener('message', onMessage);
    }, []);

    const renderNotificationText = (notificationId, notificationTitle, notificationDescription, helpLinkText, redirectLink) => {
        return (
            <React.Fragment>
                <Row>
                    <TextView title={notificationTitle} size={"Small"} type={"Bold"}/>
                </Row>
                <Row>
                    <TextView title={notificationDescription} size={"Small"} type={"Simple"}/>
                </Row>
                {redirectLink ?
                    <Row>
                        <Button onClick={() => redirectFromNotification(notificationId, redirectLink)} type="link" size="small" className={"helpLinkTextButton"}>{helpLinkText}</Button>
                    </Row> : null}
            </React.Fragment>
        )
    }

    return (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
            {toast => (
                <React.Fragment>
                    <Alert toast={true} onClose={toast.onClose} type={"informational"} width={"100%"}>
                        {renderNotificationText(toast[Constants.NOTIFICATION_ID], toast[Constants.MESSAGE][Constants.TITLE], toast[Constants.MESSAGE][Constants.MESSAGE_BODY], Constants.SHOW_ME_MORE, toast[Constants.REDIRECT_LINK])}
                    </Alert>
                </React.Fragment>
            )}
        </Toaster>
    );
}

export default HelpRequestNotificationView;
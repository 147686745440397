import React, {useEffect, useState} from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Card from "@amzn/meridian/card";
import StowMetricsView from "./StowMetricsView";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import PropTypes from "prop-types";
import { css } from "emotion";
import Column from "@amzn/meridian/column";
import { InsightMetrics } from "@amzn/dolphin-web-framework";
import { CONSENT_STATUS } from "./Constants";
import InductMetricsView from "./InductMetricsView";
import { getConsentStatus, updateConsentStatus } from "./Utils";

const ProcessMetricView = (props) => {

    const [consentStatus, setConsentStatus] = useState(null);
    const [isUserSelectedToAskLater, setIsUserSelectedToAskLater] = useState(false);

    const centerAlignText = css({
        textAlign: "center"
    });

    const linkText = css({
        padding: "0px",
        color: "#077398",
        textDecoration: "underline",
        textDecorationColor: "#077398"
    })

    useEffect(() => {
        if (props.stage && props.region && props.metricType) {
            getConsentStatus(props.stage, props.region, props.metricType, setConsentStatus);
        }
    }, []);

    const onAcceptConsentButtonClick = () => {
        if (props.stage && props.region && props.metricType) {
            updateConsentStatus(props.stage, props.region, props.metricType, true, setConsentStatus);
        }
    }

    const onAskMeLaterButtonClick = () => {
        if (props.stage && props.region && props.metricType) {
            setIsUserSelectedToAskLater(true);
        }
    }

    const onDeclineConsentButtonClick = () => {
        if (props.stage && props.region && props.metricType) {
            updateConsentStatus(props.stage, props.region, props.metricType, false, setConsentStatus);
        }
    }

    const renderConsentOptions = () => {
        const processName = props.metricType === InsightMetrics.INDUCT_INDUCT ? "Induct" : "Stow";
        return (
            <Column>
                <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                    <Box>
                        <Text className={centerAlignText}>{`Show me my ${processName} rate`}</Text>
                    </Box>
                </Row>
                <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                    <Button onClick={onAcceptConsentButtonClick}>Accept</Button>
                    <Button onClick={onAskMeLaterButtonClick} type={"secondary"}>Ask me later</Button>
                </Row>
                <Row alignmentHorizontal={"end"} width={"100%"}>
                    <span onClick={onDeclineConsentButtonClick}><Text type={"b200"} className={linkText}>{`Don't ask me again`}</Text></span>
                </Row>

            </Column>
        )
    }

    const renderProcessMetrics = () => {
        // If required props are not sent, display nothing.
        if (!props.stage || !props.region || !props.metricType) {
            return null;
        }

        // Display nothing until consent status is not fetched.
        if (!consentStatus) {
            return null;
        }

        // Display nothing if user selected to ask later.
        if (isUserSelectedToAskLater) {
            return null;
        }

        if (consentStatus === CONSENT_STATUS.NOT_FOUND) {
            return (
                <Card width={"100%"} spacingInset={"200"}>
                    {renderConsentOptions()}
                </Card>
            );
        } else if (consentStatus === CONSENT_STATUS.CONSENTED) {
            if (props.metricType === InsightMetrics.INDUCT_INDUCT) {
                return (
                    <InductMetricsView {...props}/>
                );
            } else if (props.metricType === InsightMetrics.STOW_STOW) {
                return (
                    <StowMetricsView {...props} />
                );
            }
        }

        // Display nothing if consent status is DECLINED or in any other case.
        return null;
    }

    return (
        renderProcessMetrics()
    );
};

ProcessMetricView.propTypes = {
    stage: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    metricType: PropTypes.string.isRequired,
};

export default ProcessMetricView;
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import PropTypes from "prop-types";
import { NativeMeshInteractor } from "@amzn/dolphin-web-framework";
import {FormattedMessage} from "react-intl";

const LockedModuleLauncherView = ({ privacyIcon, alignment}) => {
    const history = useHistory();

    const handleButtonClick = () => {
        history.push('/SCCTokenScan');
    }

    const exitButtonCallback = () => {
        NativeMeshInteractor.exitModule();
    }
    return (
        <Column height="100%">
            <Column width="100%" spacingInset="small" alignmentHorizontal={alignment}>
                <Text type="h500">
                    <FormattedMessage
                        id="LockedModuleLauncherView.Text.header"
                        defaultMessage="How to Access"
                    />
                </Text>
                <Row alignmentHorizontal={alignment} width="100%">
                    <img src={privacyIcon} alt="Not found" style={{width: "70%", height: "50%" }}/>
                </Row>
                <Row width="90%" widths="fill">
                    <Text type="b350" alignment={alignment}>
                        <FormattedMessage
                            id="LockedModuleLauncherView.Text.content"
                            defaultMessage="Some Dolphin modules now require extra verification. Find PA or Shift Manager to get a code allowing you to access those modules. Code will be valid until the end of your shift or until you logout."
                        />
                    </Text>
                </Row>
            </Column>
            <Column spacing ="300" alignmentHorizontal={alignment} >
                <Row width="90%" widths="fill">
                    <Button size="large" onClick={handleButtonClick}>
                        <FormattedMessage
                            id="LockedModuleLauncherView.button.next"
                            defaultMessage="Next"
                        />
                    </Button>
                </Row>
                <Row width="90%" widths="fill">
                    <Button size="large" type="Secondary" onClick={exitButtonCallback}>
                        <FormattedMessage
                            id="LockedModuleLauncherView.button.close"
                            defaultMessage="Close"
                        />
                    </Button>
                </Row>
            </Column>
        </Column>
    )
}

LockedModuleLauncherView.propTypes = {
    privacyIcon: PropTypes.string.isRequired,
    alignment: PropTypes.string,
}

export default LockedModuleLauncherView;

export default {
   Events : {
       OPERATION_API_CALL: "operation_api_call",
       USER_SCANNED_PACKAGE: "user_scanned_package",
       USER_LOADED_SCREEN: "user_loaded_screen",
       LIDAR_OBSTRUCTION: "Obstruction",
       DOLPHIN_TO_TC_CONNECTION_LATENCY: "dolphin_to_tc_connection_latency",
       IOT_AUTOMATION_PACKAGE_SCAN_LATENCY: "iot_automation_package_scan_latency",
       USER_COMPLETED_POSTURE_ASSESSMENT_1: "user_completed_posture_assessment_1",
       USER_COMPLETED_POSTURE_ASSESSMENT_2: "user_completed_posture_assessment_2",
       USER_COMPLETED_POSTURE_TRAINING: "user_completed_posture_training",
       USER_TOOK_PHOTO: "photo_captured",
       IOT_AUTOMATION_INVALID_DESTINATION_DETECTION: "iot_automation_invalid_destination_detection",
       IOT_AUTOMATION_WS_PING_MISSING: "iot_automation_ws_ping_missing",
       IOT_AUTOMATION_WS_PING_TAT: "iot_automation_ws_ping_tat",
       IOT_AUTOMATION_MANUAL_DESTINATION_SCAN_AT_DISABLED_DESTINATION: "iot_automation_manual_destination_scan_at_disabled_destination",
       IOT_AUTOMATION_CONNECT_WS: "iot_automation_connect_ws",
       IOT_AUTOMATION_WS_CONNECTION_ERROR: "iot_automation_ws_connection_error",
       IOT_AUTOMATION_WS_CONNECTION_CLOSE: "iot_automation_ws_connection_close",
       IOT_AUTOMATION_TC_NOT_AVAILABLE: "iot_automation_tc_not_available",
       STOW_DESTINATION_SCAN: "stow_destination_scan",
       STOW_MANUAL_DESTINATION_SCAN: "stow_manual_destination_scan",
       USER_VIEWED_INDUCT_PACKAGE_INSIGHT: "user_viewed_induct_package_insight",
       USER_VIEWED_STOW_PACKAGE_INSIGHT: "user_viewed_stow_package_insight",
       MOOD_SURVEY_RESPONSE: "mood_survey_response"
   },
   Modules : {
       INDUCT_PACKAGE: "INDUCT_PACKAGE",
       INDUCT_INDUCT_LOCATION: "INDUCT_INDUCT_LOCATION",
       INDUCT_GET_SAL_FORMAT: "INDUCT_GET_SAL_FORMAT",
       DIRECTED_INDUCT: "Directed Induct",
       PWA_DIRECTED_INDUCT: "PWA Directed Induct",
       PWA_DIRECTED_INDUCT_PRINT: "PWA Directed Induct Print",
       PWA: "PWA",
       ACCESS_TOKEN_EXCHANGE: "PandaTokenExchange",
       FIXIT: "FixIt",
       GSF_FIXIT: "GSF_FIXIT",
       GET_SORT_INFO_FOR_PACKAGE: "GET_SORT_INFO_FOR_PACKAGE",
       PROCESS_STOW: "PROCESS_STOW",
       VALIDATE_LOCATION_BEFORE_STOW: "VALIDATE_LOCATION_BEFORE_STOW",
       VALIDATE_OPEN_BAG: "VALIDATE_OPEN_BAG",
       OPEN_BAG: "OPEN_BAG",
       VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT: "VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT",
       GET_LOCATION_FOR_CART_RESET: "GET_LOCATION_FOR_CART_RESET",
       ATTACH_CONTAINER_TO_DESTINATION_OUTPUT: "ATTACH_CONTAINER_TO_DESTINATION_OUTPUT",
       GET_ROUTES_FOR_SORT_LOCATION: "GET_ROUTES_FOR_SORT_LOCATION",
       GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION: "GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION",
       STOW_GET_SAL_TEMPLATE_FOR_PRINTER: "STOW_GET_SAL_TEMPLATE_FOR_PRINTER",
       GET_SCOPE_FOR_NODE_OUTPUT: "GET_SCOPE_FOR_NODE_OUTPUT",
       SCRUB_CONTAINER: "SCRUB_CONTAINER",
       MOVE_TO_PROBLEM_SOLVE: "MOVE_TO_PROBLEM_SOLVE",
       GET_BAG_SORT_INFO_FOR_CLOSE: "GET_BAG_SORT_INFO_FOR_CLOSE",
       CLOSE_BAG: "CLOSE_BAG",
       CLOSE_CONTAINER_OUTPUT: "CLOSE_CONTAINER_OUTPUT",
       VALIDATE_CONTAINER_BEFORE_SCRUB: "VALIDATE_CONTAINER_BEFORE_SCRUB",
       SBL_SOURCE_SCAN: "SBL_SOURCE_SCAN",
       SBL_DESTINATION_SCAN: "SBL_DESTINATION_SCAN",
       BATCH_GET_ITEM_STAGE_INFO: "BATCH_GET_ITEM_STAGE_INFO",
       STOW: "STOW",
       PACKAGE_STOW: "PACKAGE_STOW",
       STOW_PACKAGE_SCAN: "PACKAGE_SCAN",
       PROBLEM_SOLVE: "PROBLEM_SOLVE",
       ASSIGN_PICK_LIST: "ASSIGN_PICK_LIST",
       ASSIGN_TOP_PRIORITY_PICKLIST: "ASSIGN_TOP_PRIORITY_PICKLIST",
       MOVE_ITEM_TO_CART: "MOVE_ITEM_TO_CART",
       REPORT_ISSUE_FOR_ITEM: "REPORT_ISSUE_FOR_ITEM",
       REPORT_ISSUE_FOR_CART: "REPORT_ISSUE_FOR_CART",
       ADD_CART_TO_PICKLIST: "ADD_CART_TO_PICKLIST",
       STAGE_CART: "STAGE_CART",
       CLOSE_CART: "CLOSE_CART",
       DETACH_CART: "DETACH_CART",
       GET_NEXT_ASSIGNED_PICKLIST: "GET_NEXT_ASSIGNED_PICKLIST",
       PICK_ITEM: "PICK_ITEM",
       GET_ELIGIBLE_NODES_FOR_PICKING: "GET_ELIGIBLE_NODES_FOR_PICKING",
       LIDAR: "LIDAR",
       GET_FAILURE_PACKAGES_FOR_CONTAINER_CLOSE: "GET_FAILURE_PACKAGES_FOR_CONTAINER_CLOSE",
       MOVE_FAILURE_PACKAGES_TO_PS: "MOVE_FAILURE_PACKAGES_TO_PS",
       HORUS: "HORUS",
       IOT_AUTOMATION_MESSAGE_SENT: "IOT_AUTOMATION_MESSAGE_SENT",
       IOT_AUTOMATION_MESSAGE_DROPPED: "IOT_AUTOMATION_MESSAGE_DROPPED",
       IOT_AUTOMATION_CROSS_STOW_ENABLED_MESSAGE_SENT:"IOT_AUTOMATION_CROSS_STOW_ENABLED_MESSAGE_SENT",
       IOT_AUTOMATION_MESSAGE_RECEIVED: "IOT_AUTOMATION_MESSAGE_RECEIVED",
       SBL_ASSIGNED_COLOR: "SBL_ASSIGNED_COLOR",
       EXECUTE_INSTRUCTION: "EXECUTE_INSTRUCTION",
       MY_INSIGHT: "MY_INSIGHT"
   }
}

import Logger from "../utils/Logger";
import BearerTokenUtils from "../gateway/BearerTokenUtils";
import SocketState from "./SocketState";
import MeshEvents from "../constants/MeshEvents";
import {getContainerId} from "../utils/ScannerUtils";
import SessionManager from "../handler/SessionManager";

const SERVER_BASE = "ws://127.0.0.1:51000";
const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
const REFRESH_TOKEN_TTL_IN_MS = 25 * 60 * 1000;
let refreshTokenIntervalCallback;

const messagePromise = {};
let connectionPromise;
export default {

    webSocketConnection: null,
    /**
     * Connecting to a WebSocketServer.
     */
    async connectWebSocket() {
        SessionManager.startNewSession();
        connectionPromise = new Promise((resolve, reject)=>{
            messagePromise.resolve = resolve;
            messagePromise.reject = reject
        });
        if(!this.webSocketConnection) {
            Logger.log.info('Attempting WS Connection from connectWebSocket');
            this.webSocketConnection = new WebSocket(SERVER_BASE);
        }
        this.webSocketConnection.onopen = async function () {
            Logger.log.info('Connected!');
            await BearerTokenUtils.setAccessToken();
            await BearerTokenUtils.setVerificationToken();
            messagePromise.resolve();
            dolphinBus.postMessage(JSON.stringify({socketState: SocketState.SOCKET_STATES.CONNECTED}));
            refreshTokenIntervalCallback = setInterval(async function(){
                await BearerTokenUtils.setAccessToken();
                }, REFRESH_TOKEN_TTL_IN_MS
            );
        };

        // Log errors
        this.webSocketConnection.onerror = function (error) {
            Logger.log.error('WebSocket Error ' + JSON.stringify(error));
            messagePromise.reject();
            this.webSocketConnection = null;
        };

        // Log messages from the server
        this.webSocketConnection.onmessage = function (event) {
            Logger.log.info('Message from Server: ' + event.data);
            try {
                const parsedEvent = JSON.parse(event.data);
                if(parsedEvent.eventName && parsedEvent.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN) {
                    parsedEvent.payload = getContainerId(parsedEvent.payload);
                    parsedEvent.sessionId = SessionManager.getSessionId();
                    dolphinBus.postMessage(JSON.stringify(parsedEvent));
                } else {
                    dolphinBus.postMessage(event.data);
                }
            } catch (e) {
                dolphinBus.postMessage(event.data);
            }
        };

        this.webSocketConnection.onclose = function (event) {
            Logger.log.info('Closed connection');
            clearInterval(refreshTokenIntervalCallback);
            this.webSocketConnection = null;
        };
        return connectionPromise;
    },
    /**
     * Sending messages to server
     */
    sendMessageToServer(payload){
        Logger.log.info('Payload ' +  JSON.stringify(payload));
        if(this.webSocketConnection && this.webSocketConnection.readyState === WebSocket.OPEN) {
            this.webSocketConnection.send(payload);
        } else {
            Logger.log.info('Attempting WS Connection from sendMessageToServer with state ' + this.webSocketConnection?.readyState);
            this.webSocketConnection = new WebSocket(SERVER_BASE);
            this.webSocketConnection.onOpen = async function () {
                this.webSocketConnection.send(payload);
            }
        }
    },
    /**
     * Closing websocket connection.
     */
    closeWebSocketConnection() {
        if(this.webSocketConnection && this.webSocketConnection.readyState === WebSocket.OPEN) {
            this.webSocketConnection.close();
            dolphinBus.close();
        }
    }
};
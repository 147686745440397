import { v4 as uuidv4 } from 'uuid';
import Logger from "../utils/Logger";

export default {
    sessionId : "",
    sessionStartTimestamp: null, // Stores session start timestamp in milliseconds.
    startNewSession() {
        this.sessionId = uuidv4();
        this.sessionStartTimestamp = Date.now();
        Logger.log.info("Generating UUID for session " + this.sessionId + " at timestamp " + this.sessionStartTimestamp);
    },
    getSessionId() {
        return this.sessionId;
    },
    getSessionStartTimestamp() {
        return this.sessionStartTimestamp;
    }
}
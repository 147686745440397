import BearerTokenUtils from "./BearerTokenUtils";
import MeshClient from "../client/MeshClient";

/**
 * AuthTokenProvider provides
 * access token generated from
 * MAP library.
 */
class AuthTokenProvider {
     getToken() {
        return new Promise(async (resolve, reject)=>{
            let accessToken = BearerTokenUtils.accessToken;
            let accessTokenRetryCount = 2;
            try {
                while ((!accessToken || BearerTokenUtils.isAccessTokenExpired()) && accessTokenRetryCount--) {
                    if (MeshClient.webSocketConnection && MeshClient.webSocketConnection.readyState === WebSocket.OPEN) {
                        await BearerTokenUtils.setAccessToken();
                    } else {
                        await MeshClient.connectWebSocket();
                    }
                    accessToken = BearerTokenUtils.accessToken;
                }
                resolve(accessToken);
            } catch (e) {
                reject(e);
            }
        });
    }
}

export default AuthTokenProvider;

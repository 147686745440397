import {InsightMetrics, LMFSAccessor} from "@amzn/dolphin-web-framework";

async function getConsentStatus(stage, region, metricType, setConsentStatus) {
    const data = await LMFSAccessor.fetchUpdateConsentStatus(stage, region, LMFSAccessor.CONSENT_REQUEST_TYPES.FETCH,
        getConsentType(metricType), false);
    if (data && data.result) {
        setConsentStatus(data.result);
    }
}

async function updateConsentStatus(stage, region, metricType, isConsented, setConsentStatus) {
    const data = await LMFSAccessor.fetchUpdateConsentStatus(stage, region, LMFSAccessor.CONSENT_REQUEST_TYPES.UPDATE,
        getConsentType(metricType), isConsented);
    if (data && data.result) {
        setConsentStatus(data.result);
    }
}

async function getInductMetrics(stage, region, setData) {
    const data = await LMFSAccessor.fetchInductMetrics(stage, region, LMFSAccessor.METRIC_QUERY_TYPE.PROCESS);
    if (data) {
        setData(data);
    }
}

async function getStowMetrics(stage, region, setData) {
    const data = await LMFSAccessor.fetchStowMetrics(stage, region, LMFSAccessor.METRIC_QUERY_TYPE.PROCESS);
    if (data) {
        setData(data);
    }
}

function getConsentType(metricType) {
    if (metricType === InsightMetrics.INDUCT_INDUCT) {
        return LMFSAccessor.CONSENT_TYPE.INDUCT_PROCESS;
    } else if (metricType === InsightMetrics.STOW_STOW) {
        return LMFSAccessor.CONSENT_TYPE.STOW_PROCESS;
    }
    return null;
}

function getNumericalDisplayValueFromData(value) {
    if (typeof value === 'number') {
        return Math.round(value);
    }
    return 'N/A';
}

export {
    getConsentStatus,
    updateConsentStatus,
    getInductMetrics,
    getStowMetrics,
    getNumericalDisplayValueFromData
};